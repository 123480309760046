<template>
  <!-- begin #footer -->
  <div id="footer" class="footer">
    &copy; 2020 Color Admin Responsive Admin Template - Sean Ngu All Rights
    Reserved
  </div>
  <!-- end #footer -->
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";

export default {
  name: "Footer",
  data() {
    return {
      pageOptions: PageOptions,
    };
  },
};
</script>
