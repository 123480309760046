<template>
  <div>
    <div class="profile">
      <div class="profile-header">
        <div class="profile-header-cover"></div>
        <div class="profile-header-content">
          <div class="profile-header-img">
            <img :src="cropImg" alt="foto" @click.prevent="showFileChooser" />
          </div>
          <div class="profile-header-info m-b-15">
            <h4 class="m-t-10 m-b-5">{{ this.nama }}</h4>
            <!-- <p class="m-b-5">{{ this.form.nama_panggilan }}</p> -->
            <p class="m-b-15">{{ this.username }}</p>
            <a
              href="javascript:;"
              v-on:click="show('editProfil')"
              class="btn btn-xs btn-yellow"
              >Edit Profil</a
            >
            <a
              href="javascript:;"
              v-on:click="show('editPassword')"
              class="btn btn-xs btn-danger ml-2"
              >Ganti Password</a
            >
          </div>
        </div>
        <ul class="profile-header-tab nav nav-tabs">
          <li class="nav-item">
            <a
              href="javascript:;"
              v-on:click="show('about')"
              v-bind:class="{ active: tab.about }"
              class="nav-link"
              data-toggle="tab"
              >PROFIL</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="profile-content">
      <div class="tab-content p-0">
        <div class="tab-pane fade" v-bind:class="{ 'show active': tab.about }">
          <div class="table-responsive form-inline">
            <table class="table table-profile">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <h4>
                      {{ this.nama }}<small>{{ this.username }}</small>
                    </h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="highlight">
                  <td class="field valign-middle">
                    Nomor Induk Kependudukan (NIK)
                  </td>
                  <td>{{ this.form.nomor_identitas }}</td>
                </tr>
                <tr class="highlight">
                  <td class="field valign-middle">Tanggal Lahir</td>
                  <td>{{ formatDateTime(this.form.tgl_lahir) }}</td>
                </tr>
                <tr class="divider">
                  <td colspan="2"></td>
                </tr>
                <tr>
                  <td class="field valign-middle">Nomor Anggota</td>
                  <td>
                    {{ this.form.no_pendukung }}
                  </td>
                </tr>
                <tr class="divider">
                  <td colspan="2"></td>
                </tr>
                <tr class="highlight">
                  <td class="field valign-middle">Nomor WA</td>
                  <td>
                    <i class="fa fa-mobile fa-lg m-r-5"></i>
                    {{ this.form.no_handphone }}
                  </td>
                </tr>
                <tr class="divider">
                  <td colspan="2"></td>
                </tr>
                <tr>
                  <td class="field valign-middle">Kabupaten</td>
                  <td>{{ this.form.kabupaten }}</td>
                </tr>
                <tr>
                  <td class="field valign-middle">Kecamatan</td>
                  <td>{{ this.form.kecamatan }}</td>
                </tr>
                <tr>
                  <td class="field valign-middle">Kelurahan</td>
                  <td>{{ this.form.kelurahan }}</td>
                </tr>
                <tr>
                  <td class="field valign-middle">RW/Dusun</td>
                  <td>{{ this.form.rw }}</td>
                </tr>
                <tr>
                  <td class="field valign-middle">TPS</td>
                  <td>{{ this.form.tps }}</td>
                </tr>
                <tr>
                  <td class="field valign-middle">Suku</td>
                  <td>{{ this.form.suku }}</td>
                </tr>
                <tr>
                  <td class="field valign-middle">Pekerjaan</td>
                  <td>{{ this.form.pekerjaan }}</td>
                </tr>
                <tr class="divider">
                  <td colspan="2"></td>
                </tr>
                <tr class="highlight">
                  <td class="field valign-middle">&nbsp;</td>
                  <td class="p-t-10 p-b-10">
                    <button
                      class="btn btn-sm btn-info m-r-5"
                      v-on:click="confirmCard(form.id_pendukung)"
                    >
                      <i class="fa fa-address-card"></i> Download Kartu Anggota
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="tab-pane fade"
          v-bind:class="{ 'show active': tab.editProfil }"
        >
          <form
            @submit.prevent="postData"
            id="formPeserta"
            enctype="multipart/form-data"
          >
            <div class="table-responsive form-inline">
              <input
                v-model="form.id_pendukung"
                type="text"
                hidden
                name="id_pendukung"
                class="form-control m-b-5"
              />
              <input
                v-model="form.foto_lama"
                type="text"
                hidden
                name="foto_lama"
                class="form-control m-b-5"
              />
              <table class="table table-profile">
                <tbody>
                  <tr>
                    <td class="field valign-middle">Nama Lengkap</td>
                    <td>
                      <input
                        v-model="form.nama_lengkap"
                        type="text"
                        name="nama_lengkap"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.nama_lengkap,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.nama_lengkap"
                      ></div>
                    </td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">
                      Nomor Induk Kependudukan (NIK)
                    </td>
                    <td>
                      <masked-input
                        v-model="form.nomor_identitas"
                        type="text"
                        name="nomor_identitas"
                        class="form-control m-b-5"
                        mask="1111111111111111"
                        :class="{
                          'is-invalid': formValidate.nomor_identitas,
                        }"
                      ></masked-input>
                      <div
                        class="text-danger"
                        v-html="formValidate.nomor_identitas"
                      ></div>
                    </td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">Tanggal Lahir</td>
                    <td>
                      <input
                        v-model="form.tgl_lahir"
                        type="date"
                        name="tgl_lahir"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.tgl_lahir,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.tgl_lahir"
                      ></div>
                    </td>
                  </tr>
                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td class="field valign-middle">Email</td>
                    <td>
                      <input
                        v-model="form.email_pendukung"
                        type="email"
                        name="email_pendukung"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.email_pendukung,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.email_pendukung"
                      ></div>
                    </td>
                  </tr>
                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">Nomor WA</td>
                    <td>
                      <masked-input
                        v-model="form.no_handphone"
                        type="text"
                        name="no_handphone"
                        class="form-control m-b-5"
                        mask="1111-1111-1111"
                        :class="{
                          'is-invalid': formValidate.no_handphone,
                        }"
                      ></masked-input>
                      <div
                        class="text-danger"
                        v-html="formValidate.no_handphone"
                      ></div>
                    </td>
                  </tr>
                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td class="field valign-middle">Kabupaten</td>
                    <td>
                      <select
                        v-model="form.kabupaten"
                        name="kabupaten"
                        class="form-control m-b-5"
                        v-on:change="getKecamatan($event)"
                        :class="{
                          'is-invalid': formValidate.kabupaten,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option
                          v-for="kabupaten in opsiKabupaten"
                          :key="kabupaten.nama_kabupaten"
                          :value="kabupaten.nama_kabupaten"
                        >
                          {{ kabupaten.nama_kabupaten }}
                        </option>
                      </select>
                      <div
                        class="text-danger"
                        v-html="formValidate.kabupaten"
                      ></div>
                    </td>
                  </tr>
                  <tr>
                    <td class="field valign-middle">Kecamatan</td>
                    <td>
                      <select
                        v-model="form.kecamatan"
                        name="kecamatan"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.kecamatan,
                        }"
                        v-on:change="getKelurahan($event)"
                      >
                        <option value="">Pilih</option>
                        <option
                          v-for="kecamatan in opsiKecamatan"
                          :key="kecamatan.nama_kecamatan"
                          :value="kecamatan.nama_kecamatan"
                        >
                          {{ kecamatan.nama_kecamatan }}
                        </option>
                      </select>
                      <div
                        class="text-danger"
                        v-html="formValidate.kecamatan"
                      ></div>
                    </td>
                  </tr>
                  <tr>
                    <td class="field valign-middle">Kelurahan</td>
                    <td>
                      <select
                        v-model="form.kelurahan"
                        name="kelurahan"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.kelurahan,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option
                          v-for="kelurahan in opsiKelurahan"
                          :key="kelurahan.nama_kelurahan"
                          :value="kelurahan.nama_kelurahan"
                        >
                          {{ kelurahan.nama_kelurahan }}
                        </option>
                      </select>
                      <div
                        class="text-danger"
                        v-html="formValidate.kelurahan"
                      ></div>
                    </td>
                  </tr>
                  <tr>
                    <td class="field valign-middle">RW/Dusun</td>
                    <td>
                      <input
                        v-model="form.rw"
                        type="text"
                        name="rw"
                        class="form-control m-b-5"
                        placeholder="Contoh 001"
                        :class="{
                          'is-invalid': formValidate.rw,
                        }"
                      />
                      <div class="text-danger" v-html="formValidate.rw"></div>
                    </td>
                  </tr>
                  <tr>
                    <td class="field valign-middle">TPS</td>
                    <td>
                      <masked-input
                        v-model="form.tps"
                        type="text"
                        name="tps"
                        class="form-control m-b-5"
                        mask="111"
                        placeholder="Contoh 001"
                        :class="{
                          'is-invalid': formValidate.tps,
                        }"
                      ></masked-input>
                      <div class="text-danger" v-html="formValidate.tps"></div>
                    </td>
                  </tr>
                  <tr>
                    <td class="field valign-middle">Suku</td>
                    <td>
                      <select
                        v-model="form.suku"
                        name="suku"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.suku,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option
                          v-for="suku in opsiSuku"
                          :key="suku.nama_suku"
                          :value="suku.nama_suku"
                        >
                          {{ suku.nama_suku }}
                        </option>
                        <option value="lain-Lain">Lain-Lain</option>
                      </select>
                      <div class="text-danger" v-html="formValidate.suku"></div>
                    </td>
                  </tr>
                  <tr>
                    <td class="field valign-middle">Pekerjaan</td>
                    <td>
                      <select
                        v-model="form.pekerjaan"
                        name="pekerjaan"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.pekerjaan,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option value="Pelajar">Pelajar/Mahasiswa</option>
                        <option value="Karyawan Swasta">Karyawan Swasta</option>
                        <option value="PNS">PNS</option>
                        <option value="Nelayan">Nelayan/Petani</option>
                        <option value="Lain-Lain">Lain-Lain</option>
                      </select>
                      <small
                        class="text-danger"
                        v-html="formValidate.pekerjaan"
                      ></small>
                    </td>
                  </tr>
                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">&nbsp;</td>
                    <td class="p-t-10 p-b-10">
                      <div>
                        <a
                          href="#"
                          class="btn btn-primary m-1"
                          role="button"
                          @click.prevent="showFileChooser"
                        >
                          <i class="fa fa-fw fa-image"></i> Pilih/Ambil Foto
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">&nbsp;</td>
                    <td class="p-t-10 p-b-10">
                      <div class="col-md-4">
                        <input
                          style="display: none"
                          ref="input"
                          type="file"
                          name="image"
                          accept="image/*"
                          @change="setImage"
                        />
                      </div>

                      <!-- <div class="row">
                          <div class="cropper-area">
                            <div class="img-cropper">
                              <vue-cropper
                                ref="cropper"
                                :aspect-ratio="1 / 1"
                                :src="imgSrc"
                                preview=".preview"
                                style="
                                  width: 100%;
                                  border-radius: 5px;
                                  overflow: hidden;
                                "
                              />
                              <div v-if="this.imgSrc">
                                <div class="btn-group mt-1">
                                  <a
                                    href="#"
                                    class="btn btn-sm btn-primary"
                                    role="button"
                                    @click.prevent="rotate(90)"
                                  >
                                    <i class="fa fa-redo"></i>
                                  </a>
                                  <a
                                    href="#"
                                    class="btn btn-sm btn-primary"
                                    role="button"
                                    @click.prevent="rotate(-90)"
                                  >
                                    <i
                                      class="fa fa-redo fa-flip-horizontal"
                                    ></i>
                                  </a>
                                  <a
                                    href="#"
                                    class="btn btn-sm btn-primary"
                                    role="button"
                                    @click.prevent="cropImage"
                                  >
                                    <i class="fa fa-crop"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </td>
                  </tr>
                  <tr class="highlight">
                    <td class="field valign-middle">&nbsp;</td>
                    <td class="p-t-10 p-b-10">
                      <!-- <button type="submit" class="btn btn-primary width-200">
                      Download Kartu Anggota
                    </button> -->
                      <button
                        type="button"
                        class="btn btn-success mt-4"
                        @click="postData()"
                        :disabled="this.loading"
                      >
                        <i
                          v-if="this.loading"
                          class="fas fa-circle-notch fa-spin"
                        ></i>
                        Update Profil
                      </button>
                    </td>
                  </tr>
                  <!-- <tr class="highlight">
                  <td class="field valign-middle">&nbsp;</td>
                  <td class="p-t-10 p-b-10">
                    <button type="submit" class="btn btn-primary width-200">
                      Download Kartu Anggota
                    </button>
                  </td>
                </tr> -->
                </tbody>
              </table>
            </div>
          </form>
        </div>
        <div
          class="tab-pane fade"
          v-bind:class="{ 'show active': tab.editPassword }"
        >
          <form @submit.prevent="updatePassword" enctype="multipart/form-data">
            <div class="table-responsive form-inline">
              <input
                v-model="form.id_pendukung"
                type="text"
                hidden
                name="id_pendukung"
                class="form-control m-b-5"
              />
              <table class="table table-profile">
                <tbody>
                  <tr>
                    <td class="field">Password Lama</td>
                    <td>
                      <input
                        v-model="form.password_lama"
                        type="password"
                        name="password_lama"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.password_lama,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.password_lama"
                      ></div>
                    </td>
                  </tr>
                  <tr>
                    <td class="field">Password Baru</td>
                    <td>
                      <input
                        v-model="form.password_baru"
                        type="password"
                        name="password_baru"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.password_baru,
                        }"
                      />
                      <div
                        class="text-danger"
                        v-html="formValidate.password_baru"
                      ></div>
                    </td>
                  </tr>
                  <tr class="highlight">
                    <td class="field">&nbsp;</td>
                    <td class="p-t-10 p-b-10">
                      <button
                        type="button"
                        class="btn btn-success mt-4"
                        @click="updatePassword()"
                        :disabled="this.loading"
                      >
                        <i
                          v-if="this.loading"
                          class="fas fa-circle-notch fa-spin"
                        ></i>
                        Ganti Password
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header></template>
      <div>
        <div class="row">
          <div class="cropper-area">
            <div class="img-cropper">
              <vue-cropper
                ref="cropper"
                :aspect-ratio="1 / 1"
                :src="imgSrc"
                preview=".preview"
                style="width: 100%; border-radius: 5px; overflow: hidden"
              />
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div>
          <div class="btn-group mt-1">
            <a
              href="#"
              class="btn btn-sm btn-primary"
              role="button"
              @click="closeModal"
              >Batal</a
            >
            <a
              href="#"
              class="btn btn-sm btn-primary"
              role="button"
              @click.prevent="rotate(90)"
            >
              <i class="fa fa-redo"></i>
            </a>
            <a
              href="#"
              class="btn btn-sm btn-primary"
              role="button"
              @click.prevent="rotate(-90)"
            >
              <i class="fa fa-redo fa-flip-horizontal"></i>
            </a>
            <a
              href="#"
              class="btn btn-sm btn-primary"
              role="button"
              @click.prevent="cropImage"
            >
              <i class="fa fa-crop"></i>
            </a>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PageOptions from "../../config/PageOptions.vue";
import axios from "axios";
import Swal from "sweetalert2";
import MaskedInput from "vue-masked-input";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import moment from "moment";

export default {
  components: {
    MaskedInput,
    VueCropper,
  },
  data() {
    return {
      foto: "/assets/img/user.png",
      username: "",
      level: "User",
      nama: "User",
      nik: "",
      tab: {
        about: true,
        editProfil: false,
        editPassword: false,
      },
      form: {
        id_pendukung: "",
        no_urut: "",
        no_pendukung: "",
        nama_lengkap: "",
        nama_panggilan: "",
        no_handphone: "",
        email_pendukung: "",
        nomor_identitas: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        rw: "",
        tps: "",
        suku: "",
        level: "",
        tgl_lahir: "",
        pekerjaan: "",
        tim_relawan_tps: false,
        foto: "",
        foto_lama: "",
        foto_crop: "",
        password_lama: "",
        password_baru: "",
      },
      link: "/api/pendukung/update",
      opsiSuku: [],
      opsiKabupaten: [],
      opsiKecamatan: [],
      opsiKelurahan: [],
      imgSrc: "",
      cropImg: "/assets/img/user.png",
      formValidate: [],
      loading: false,
      modalVisible: false,
      conditionMet: false,
    };
  },
  mounted() {
    this.getKabupaten();
    this.getSuku();
  },
  created() {
    PageOptions.pageContentFullWidth = true;
    const user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
      this.foto = this.user.foto;
      this.level = this.user.level;
      this.nama = this.user.nama;
      this.nik = this.user.nik;
      this.username = this.user.username;
      this.fetchData();
    } else {
      // this.$router.push("/login");
    }
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageContentFullWidth = false;
    next();
  },
  methods: {
    show: function (x) {
      this.tab.about = false;
      this.tab.editProfil = false;
      this.tab.editPassword = false;

      switch (x) {
        case "about":
          this.tab.about = true;
          break;
        case "editProfil":
          this.tab.editProfil = true;
          break;
        case "editPassword":
          this.tab.editPassword = true;
          break;
        default:
          this.tab.about = true;
          break;
      }
    },
    updatePassword() {
      this.loading = true;
      const formData = new FormData();

      formData.append("id_pendukung", this.form.id_pendukung);
      formData.append("password_lama", this.form.password_lama);
      formData.append("password_baru", this.form.password_baru);
      // console.log(formData.get("foto"));
      axios
        .post("/api/auth/changePassword/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              if (response.data.status) {
                localStorage.removeItem("user");
                if (this.$route.path !== "/login") {
                  this.$router.push("/login");
                }
              }
              // this.$router.push("/profil");
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();

      if (this.cropImg.includes("http") || this.cropImg == "") {
        // formData.append("foto", "");
      } else {
        const blob = this.dataURLtoBlob(this.cropImg);
        formData.append("foto", blob, "foto.png");
      }

      formData.append("id_pendukung", this.form.id_pendukung);
      formData.append("no_urut", this.form.no_urut);
      formData.append("no_pendukung", this.form.no_pendukung);
      formData.append("nama_lengkap", this.form.nama_lengkap);
      formData.append("nama_panggilan", this.form.nama_panggilan);
      formData.append("no_handphone", this.form.no_handphone);
      formData.append("email_pendukung", this.form.email_pendukung);
      formData.append("nomor_identitas", this.form.nomor_identitas);
      formData.append("kabupaten", this.form.kabupaten);
      formData.append("kecamatan", this.form.kecamatan);
      formData.append("kelurahan", this.form.kelurahan);
      formData.append("rw", this.form.rw);
      formData.append("tps", this.form.tps);
      formData.append("suku", this.form.suku);
      formData.append("level", this.form.level);
      formData.append("tgl_lahir", this.form.tgl_lahir);
      formData.append("pekerjaan", this.form.pekerjaan);
      formData.append("tim_relawan_tps", this.form.tim_relawan_tps);
      formData.append("foto_lama", this.form.foto_lama);

      // console.log(formData.get("foto"));
      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              if (response.data.status) {
                localStorage.removeItem("user");
                if (this.$route.path !== "/login") {
                  this.$router.push("/login");
                }
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/api/profil/detail/" + this.nik)
        .then((response) => {
          this.form.id_pendukung = response.data.data.id_pendukung;
          this.form.no_urut = response.data.data.no_urut;
          this.form.no_pendukung = response.data.data.no_pendukung;
          this.form.nama_lengkap = response.data.data.nama_lengkap;
          this.form.nama_panggilan = response.data.data.nama_panggilan;
          this.form.no_handphone = response.data.data.no_handphone;
          this.form.no_handphone = response.data.data.no_handphone;
          this.form.email_pendukung = response.data.data.email_pendukung;
          this.form.nomor_identitas = response.data.data.nomor_identitas;
          this.form.kabupaten = response.data.data.kabupaten;
          this.form.kecamatan = response.data.data.kecamatan;
          this.form.kecamatan = response.data.data.kecamatan;
          this.form.kelurahan = response.data.data.kelurahan;
          this.form.rw = response.data.data.rw;
          this.form.tps = response.data.data.tps;
          this.form.suku = response.data.data.suku;
          this.form.level = response.data.data.level;
          this.form.tgl_lahir = response.data.data.tgl_lahir;
          this.form.pekerjaan = response.data.data.pekerjaan;
          this.form.foto_lama = response.data.data.foto;
          this.cropImg = response.data.data.link_foto;
          if (response.data.data.tim_relawan_tps == "Ya") {
            this.form.tim_relawan_tps = true;
          } else {
            this.form.tim_relawan_tps = false;
          }
          this.getOption();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getSuku() {
      axios
        .get("/api/suku")
        .then((response) => {
          this.opsiSuku = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKabupaten() {
      axios
        .get("/api/daerah/kabupaten")
        .then((response) => {
          this.opsiKabupaten = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKecamatan(event) {
      this.form.kabupaten = event.target.value;
      axios
        .get("/api/daerah/kecamatan/" + event.target.value)
        .then((response) => {
          this.opsiKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKelurahan(event) {
      this.form.kecamatan = event.target.value;
      axios
        .get("/api/daerah/kelurahan/" + event.target.value)
        .then((response) => {
          this.opsiKelurahan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getOption() {
      if (this.form.kabupaten) {
        axios
          .get("/api/daerah/kecamatan/" + this.form.kabupaten)
          .then((response) => {
            this.opsiKecamatan = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
      if (this.form.kecamatan) {
        axios
          .get("/api/daerah/kelurahan/" + this.form.kecamatan)
          .then((response) => {
            this.opsiKelurahan = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },

    dataURLtoBlob(dataURL) {
      const base64Data = dataURL.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "image/png" });
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.modalVisible = false;
      this.conditionMet = true;
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
      this.modalVisible = true;
      this.conditionMet = false;
    },

    confirmCard(id) {
      Swal.fire({
        title: "Apakah Anda Akan Mengunduh Kartu Anggota ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya!",
      }).then((result) => {
        if (result.value) {
          this.downloadCard(id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Dibatalkan", "", "error");
        }
      });
    },
    downloadCard(id) {
      try {
        axios
          .get("/api/profil/card/" + id)
          .then((response) => {
            if (response.status === 200) {
              const imageUrl = response.data.card;
              fetch(imageUrl)
                .then((response) => response.blob())
                .then((blob) => {
                  const url = window.URL.createObjectURL(new Blob([blob]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "kartu_anggota.png");
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },

    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },

    formatDateTime(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
  },
};
</script>